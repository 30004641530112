import styled from 'styled-components';
import { layoutSizes, colours } from "../../../constants/css";
var ArrowRight = "/static/slick/Icon-Arrow-right.svg";
export var ArrowPrev = styled.div.withConfig({
  displayName: "style__ArrowPrev",
  componentId: "sc-10v0m88-0"
})(["display:none;@media only screen and (min-width:", "){display:block;background:", ";height:50px;width:50px;border-radius:50%;position:absolute;top:40%;padding:0;cursor:pointer;left:1.25rem;z-index:1;background-image:url('", "');background-repeat:no-repeat;-webkit-transform:rotate(90deg);transform:rotate(90deg);background-position:center;}"], layoutSizes.desktopWidth, colours.qantasGrey, ArrowRight);
export var HeroPrev = styled(ArrowPrev).withConfig({
  displayName: "style__HeroPrev",
  componentId: "sc-10v0m88-1"
})(["border-radius:0;width:400px;left:-160px;height:80px;background-color:rgba(50,50,50,0.6);"]);
export var HeroNext = styled(HeroPrev).withConfig({
  displayName: "style__HeroNext",
  componentId: "sc-10v0m88-2"
})(["right:-160px;left:auto;transform:rotate(-90deg);"]);
export var Banner = styled.section.withConfig({
  displayName: "style__Banner",
  componentId: "sc-10v0m88-3"
})(["background:url('", "') no-repeat;height:250px;width:300px;border-radius:4px;margin:0 5px;@media only screen and (min-width:", "){background:url('", "') no-repeat;height:400px;width:750px;margin:0 15px;}"], function (props) {
  return props.mobileImage;
}, layoutSizes.desktopWidth, function (props) {
  return props.desktopImage;
});
export var BannerWrapper = styled.div.withConfig({
  displayName: "style__BannerWrapper",
  componentId: "sc-10v0m88-4"
})(["position:relative;.slick-list{overflow:hidden;height:250px;@media only screen and (min-width:", "){height:400px;}}"], layoutSizes.desktopWidth);
export var LinkWrapper = styled.div.withConfig({
  displayName: "style__LinkWrapper",
  componentId: "sc-10v0m88-5"
})(["cursor:pointer;"]);
import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
import { layoutSizes } from "../../constants/css";
import { Container, ArrowLink } from "../_global";
import OfferGrid from "../Offer/Grid";
import OfferCard from "../Offer/Card";
import { commonOlmProps } from "../Offer/offerUtils";
var ShopByStoreContainer = styled(Container).withConfig({
  displayName: "ShopByStore__ShopByStoreContainer",
  componentId: "sc-z8fj6c-0"
})(["scroll-margin-top:4rem;position:relative;margin:2.625rem auto;::before{display:block;content:' ';margin-top:-5rem;height:5rem;visibility:hidden;pointer-events:none;}> h1{position:relative;font-size:1.675rem;margin-top:0;margin-bottom:2rem;> a{position:absolute;top:50%;right:0;transform:translateY(-50%);padding-right:1rem;font-size:initial;font-weight:initial;}}@media only screen and (min-width:", "){> h1{font-size:1.875rem;}}"], layoutSizes.desktopWidth);
var ShopByStore = function ShopByStore(_ref) {
  var anchor = _ref.anchor,
    featuredMerchants = _ref.featuredMerchants,
    bonusRibbons = _ref.bonusRibbons,
    title = _ref.title;
  return __jsx(ShopByStoreContainer, {
    id: anchor,
    wideView: true
  }, __jsx("h1", null, title, __jsx(ArrowLink, {
    text: "View all stores",
    href: "/all-stores"
  })), __jsx(OfferGrid, {
    hasHow: false
  }, featuredMerchants.filter(function (m) {
    return m && m.is_elevated;
  }).sort(function (a, b) {
    return (
      // eslint-disable-next-line no-nested-ternary
      a.merchantName.toLowerCase() > b.merchantName.toLowerCase() ? 1 : b.merchantName.toLowerCase() > a.merchantName.toLowerCase() ? -1 : 0
    );
  }).map(function (merchant) {
    var commonProps = commonOlmProps(merchant);
    return __jsx(OfferCard, _extends({}, commonProps, {
      bonusRibbons: bonusRibbons
    }));
  })));
};
ShopByStore.defaultProps = {
  anchor: '',
  featuredMerchants: [],
  title: '',
  bonusRibbons: []
};
export default ShopByStore;